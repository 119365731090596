import { authenticatedFetch, getApiUrl } from "~/utils/ApiUtils";
import { useQuery, useMutation, UseQueryOptions } from "@tanstack/react-query";
import {
  FeatureAccessConfigurationValueType,
  SystemStatusType,
} from "~/types/SystemTypes";
import { MutationOptionsType } from "~/types/ApiTypes";
// ================== GET SYSTEM STATUS ==================

export const getSystemStatus = async () => {
  const url = `${getApiUrl()}/system_status`;
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const payload = (await response.json()) as SystemStatusType;
  if (!payload) {
    throw new Error("Invalid response from getSystemStatus.");
  }
  return payload;
};

export const useGetSystemStatusQuery = (staleTime?: number) => {
  return useQuery({
    queryKey: ["systemStatus"],
    queryFn: () => {
      return getSystemStatus();
    },
    staleTime,
  });
};

// ================== SET SYSTEM STATUS ==================

interface SetSystemStatusParamsType {
  maintenanceMode?: boolean;
  maintenanceMessage?: string;
}

export const setSystemStatus = async ({
  maintenanceMode,
  maintenanceMessage,
}: SetSystemStatusParamsType) => {
  const url = `${getApiUrl()}/system_status`;
  const response = await authenticatedFetch(url, {
    method: "POST",
    body: JSON.stringify({
      maintenance_mode: maintenanceMode,
      maintenance_message: maintenanceMessage,
    }),
  });
  const payload = (await response.json()) as SystemStatusType;
  if (!payload) {
    throw new Error("Invalid response from setSystemStatus.");
  }
  return payload;
};

export const useSetSystemStatusMutation = <T extends SystemStatusType>(
  mutationOptions?: MutationOptionsType<T>
) => {
  return useMutation({
    mutationFn: (params: SetSystemStatusParamsType) => {
      return setSystemStatus(params);
    },
    onSuccess: (data) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(data as T);
      }
    },
  });
};

// ================== GET FEATURE ACCESS CONFIGURATION ==================

export const getFeatureAccessConfiguration = async () => {
  const url = `${getApiUrl()}/feature_access`;
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const payload =
    (await response.json()) as FeatureAccessConfigurationValueType;
  if (!payload) {
    throw new Error("Invalid response from getFeatureAccessConfiguration.");
  }
  return payload;
};

export const useGetFeatureAccessConfigurationQuery = (
  options?: UseQueryOptions<FeatureAccessConfigurationValueType>
) => {
  return useQuery({
    queryKey: ["featureAccessConfiguration"],
    queryFn: () => {
      return getFeatureAccessConfiguration();
    },
    ...options,
  });
};

// ================== SET FEATURE ACCESS CONFIGURATION ==================

interface SetFeatureAccessConfigurationParamsType
  extends FeatureAccessConfigurationValueType {}

export const setFeatureAccessConfiguration = async (
  params: SetFeatureAccessConfigurationParamsType
) => {
  const url = `${getApiUrl()}/feature_access`;
  const response = await authenticatedFetch(url, {
    method: "POST",
    body: JSON.stringify(params),
  });
  const payload =
    (await response.json()) as FeatureAccessConfigurationValueType;
  return payload;
};

export const useSetFeatureAccessConfigurationMutation = <
  T extends FeatureAccessConfigurationValueType
>(
  options?: MutationOptionsType<T>
) => {
  return useMutation({
    mutationFn: (params: SetFeatureAccessConfigurationParamsType) => {
      return setFeatureAccessConfiguration(params);
    },
    onSuccess: (data) => {
      if (options?.onSuccess) {
        options?.onSuccess(data as T);
      }
    },
  });
};

// ================== GET DIALER CONFIGURATION ==================

export const getDialerConfiguration = async () => {
  const url = `${getApiUrl()}/dialer_configuration`;
  const response = await authenticatedFetch(url);
  const payload = (await response.json()) as Record<string, any>;
  return payload;
};

export const useGetDialerConfigurationQuery = (
  options?: UseQueryOptions<Record<string, any>>
) => {
  return useQuery({
    queryKey: ["dialerConfiguration"],
    queryFn: () => {
      return getDialerConfiguration();
    },
    ...options,
  });
};

// ================== SET DIALER CONFIGURATION ==================

interface SetDialerConfigurationParamsType extends Record<string, any> {}

export const setDialerConfiguration = async (
  params: SetDialerConfigurationParamsType
) => {
  const url = `${getApiUrl()}/dialer_configuration`;
  const response = await authenticatedFetch(url, {
    method: "POST",
    body: JSON.stringify(params),
  });
  const payload = (await response.json()) as Record<string, any>;
  return payload;
};

export const useSetDialerConfigurationMutation = <
  T extends Record<string, any>
>(
  options?: MutationOptionsType<T>
) => {
  return useMutation({
    mutationFn: (params: SetDialerConfigurationParamsType) => {
      return setDialerConfiguration(params);
    },
    onSuccess: (data) => {
      if (options?.onSuccess) {
        options?.onSuccess(data as T);
      }
    },
  });
};
