import { UserTypeEnum } from "~/constants/UserConstants";
import useCurrentUser from "~/hooks/useCurrentUser";
import { userHasPermissionOrHigher } from "~/utils/UserUtils";

const useIsUserType = (
  userType: UserTypeEnum,
  options?: { exact?: boolean }
) => {
  const user = useCurrentUser();
  if (options?.exact) {
    return user?.user_type === userType;
  }
  return userHasPermissionOrHigher(user, userType);
};

export default useIsUserType;
